import React from "react";
import Home from "./pages/Home";
import Nav from "./global/Nav";
import Services from "./pages/Services";
function App() {
  return (
    <div className="App">
     <Nav/>
    {/* <Services/> */}
    <Home/>
    </div>
  );
}

export default App;
