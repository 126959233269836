import React from 'react'

const Nav = () => {
  return (
    <div className='absolute  text-xl z-10 top-0 w-full px-4 md:px-10 py-5 flex justify-between items-center tracking-wide text-orange-500/90'>
    <div className=''>
    <h1 className='font-bold text-sm md:text-xl tracking-widest  logo '> <span className='text-xl md:text-2xl'>F</span>irst <span className='text-xl md:text-2xl'>G</span>roup <span className='text-xl md:text-2xl'>C</span>onsultant</h1>
    </div>
    
    <div className='hidden md:block' >
        <ul className='flex gap-10'>
            <li className='cursor-pointer hover:scale-105 hover:text-white/90  border-white/80 gradient-border hover:border-orange-400  border-spacing-3.5 duration-200 hover:border-b'>
                Home
            </li>
            <li className='cursor-pointer hover:scale-105 hover:text-white/90 border-white/80 gradient-border hover:border-orange-400 duration-200 hover:border-b '>
                Services
            </li >
            <li className='cursor-pointer hover:scale-105 hover:text-white/90 border-white/80 gradient-border hover:border-orange-400 duration-200 hover:border-b '>
                About
            </li>
            <li className='cursor-pointer hover:scale-105 hover:text-white/90 border-white/80 gradient-border hover:border-orange-400 duration-200 hover:border-b '>
                Contact
            </li>
        </ul>
    </div>
    
    </div>
  )
}

export default Nav