import React from 'react'
import design from '../assets/vector1.png'
import design1 from '../assets/vector0.png'
import design2 from '../assets/vector2.png'
const Home = () => {
  return (
    <div className='bg-black/90 h-[100vh]  flex-col md:px-10 '>
        <div className='md:block hidden absolute z-0 hover:scale-95 duration-200 -bottom-72 -left-20'>
            <img src={design1} alt='?'/>
        </div>
        <div className='md:hidden block absolute z-0 hover:scale-95 duration-200 h-28 w-28  bottom-0 right-5'>
            <img src={design1} alt='?'/>
        </div>
        
        
     <div className='flex z-10'>
     <div>
      <div className='flex-col flex md:flex-row py-20 justify-end'>
            <div className=''></div>
        </div>
        <div>
            <div className='px-4 md:px-20 space-y-5'>
              <div className='flex gap-4'>
              <div className='px-8 py-8 md:py-24 bg-yellow-700 w-2/5 md:w-2/3'></div>
              <div className='w-3/5 h-32 px-4 hover:scale-95 duration-200 z-0 md:hidden ' > 
         
              </div>
       </div>
           <h1 className=' text-2xl md:text-5xl font-bold tracking-widest z-10 text-white'>First Group Consultants</h1>
           </div>
           
        </div>
      </div>
       <div className='hidden md:block hover:scale-95  duration-200 z-0 pt-6' > 
        <img className=' h-28 w-36 md:h-auto md:w-auto' src={design} alt="?"/>
       </div>
     </div>
     <div className='md:pt-8 pt-20 flex justify-center md:justify-end md:pr-24 gap-1  items-center '>
       <h1 className=' cursor-pointer text-md md:text-2xl text-center mb-2 md:mb-5  text-white tracking-wider font-semibold'>Browse through our Services</h1> 
       <div className='duration-200 hover:scale-105 cursor-pointer'>
            <img className='h-12 w-8 md:h-32 md:w-auto' src={design2} alt='?'/>
       </div>
     </div>
       
    </div>
  )
}

export default Home